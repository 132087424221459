<template>
  <div class="form-group" >
    <label v-if="showLabels" :for="name">{{ title }}
      <span class="text-danger" v-show="required">*</span>
      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </label>
      <multiselect
        :multiple="multiple"
        :max-height="150"
        :placeholder="$t('general.select_option')"
        :tagPlaceholder="$t('general.please_enter_to_create_tag')"
        :selectLabel="$t('general.please_enter_to_select')"
        :selectGroupLabel="$t('general.please_enter_to_select_group')"
        :selectedLabel="$t('general.selected')"
        :deselectLabel="$t('general.please_enter_to_remove')"
        :deselectGroupLabel="$t('general.please_enter_to_deselect_group')"
        :show-labels="showLabels"
        :disabled="disabled"
        :searchable="searchable"
        @select="onSelect"
        :track-by="trackBy"
        :label="label"
        :allow-empty="allowEmpty"
        v-model="modelProxy"
        :options="values"
      ></multiselect>
<!--    <select :multiple="multiple" class="form-control" :id="name" v-model="modelProxy" :required="required" :disabled="disabled">-->
<!--      <option :value="index" v-for="(value, index) in values">{{ $t(value) }}</option>-->
<!--    </select>-->
    <p class="text-danger pt-1 px-3 block" v-if="error">{{ error }}</p>
  </div>
</template>

<script>

import 'vue-multiselect/dist/vue-multiselect.min.css'
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "MultipleSelectInput",
  props: {
    showLabels:{
      required: false,
      default: true,
    },
    title: {},
    name: {},
    values: {},
    error: {},
    model: {},
    required: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false
    },
    allowEmpty: {
      required: false,
      default: true
    },
    trackBy: {
      required: false,
      default: "id"
    },
    label: {
      required: false,
      default: "name"
    },
    searchable: {
      required: false,
      default: false
    },
    multiple: {
      required: false,
      default: false,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
  },
  components: {
    InfoTooltip
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value)
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
}
</script>

<style scoped>
.multiselect{
  height: 40px !important;
}
</style>
