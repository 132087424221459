<template>
<span
    class="text-warning cursor-pointer"
    :content="helperText"
    v-tippy
><i class="fas fa-info-circle text-warning font-size-base pulse pulse-warning d-inline-block px-2"></i></span>
</template>

<script>
export default {
name: "InfoTooltip",
  props: {
    helperText: {
      default: '',
      required: true,
    }
  }
}
</script>

<style scoped>

</style>